
.container {
  top: 0;
  height: 90vh;
  display: grid;
  align-items: center;
  margin: 0 auto;
  position: relative;
  border-radius: 32px;
  overflow: hidden;
  width: min(95vw, 1680px);
  background: var(--green-background);
}
  .content_bg{
    inset: 0;
    z-index: 1;
    height: 100vh;
    position: absolute;
    background: #00000055;
  }

.video_container {
  inset: 0;
  height: 100vh;
  object-fit: cover;
  overflow: hidden;
  position: absolute;
  transition: 100ms linear;
}
  
  .video {
    top: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: absolute;
  }

  .text{
    flex:1;
    width: 100%;
    z-index: 1;
    display: flex;
    margin: 0 auto;
    max-width: 900px;
    text-align: start;
    justify-content: end;
    flex-direction: column;
  }
    
  .welcome_button{
    margin-top: 1rem;
    box-shadow: 0 0 50px #202b2c;
    background: var(--green-background);
  }

  .video_button{
    border: none;
    font-size: 14px;
    margin-top: 1rem;
    font-weight: 400;
    padding: 14px 18px;
    background:transparent;
  }