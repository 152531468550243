.content{
    gap: 32px;
    width: 100%;
    padding: 12px;
    display: flex;
    margin: 0px auto;
    flex-wrap: wrap;
    max-width: 1080px;
    background: rgb(255, 255, 255);
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 980px) {
    .content{
        gap: 12px;
        flex-direction: column;
        justify-content: center;
    }
}