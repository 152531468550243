.footer{
    gap: 32px;
    z-index: 2;
    color: #FFF;
    display: flex;
    position: relative;
    padding: 56px 86px;
    justify-content: center;
    background: #143c41;
}
@media only screen and (max-width: 760px) {
    .footer{
        gap: 0;
        padding: 50px;
        grid-template-columns: 1fr;
    }
}

.menu{
    gap: 128px;
    display: flex;
    text-align: start;
    flex-direction: row;
}

@media (max-width: 886px) {
    .menu{
        padding-top: 0;
        margin-top: 50px;
    }
}

.navigation > span{
    cursor: pointer;
    color: #FFFFFFCC;
    transition: 250ms ease-in-out;
}
.navigation > span:hover{
    color: #FFF;
}