.container{
  min-height: auto;
  background: var(--white-background)
}


@media (max-width:880px) {
  .content {
    padding: 0;
  }
}

.header_desc{
  font-size: 18px;
  text-align: start;
}

.grid {
  gap:32px;
  display: grid;
  margin: 0 auto;
  max-width: 980px;
  grid-template-columns: 1fr 1fr;
}

  @media only screen and (max-width: 760px) {
    .grid {
      gap: 0;
      grid-template-columns: 1fr 0;
    }
}
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.item{
  gap:16px;
  display: grid;
  grid-template-columns: 50px 1fr;
}
.items_icon{
  color: #FFF;
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  align-items: center;
  justify-content: center;
  background: rgb(153,111,49);
}

.video_container{
    height: 40vh;
    overflow: hidden;
    position: relative;
    border-radius: 32px;
}
.video{
  object-fit: cover;
  width: 100%;
  height: 100%;
}