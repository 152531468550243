
.container{
    z-index: 2;
    margin: 0 auto;
    max-width: 950px;
    text-align: start;
    position: relative;
}


@media (max-width: 760px) {
    .content_bg{
        width: 100%;
        border-radius: 0;
    }
}

.content{
    gap: 64px;
    display: grid;
    color: #FFF;
    padding: 38px 64px;
    border-radius: 24px;
    grid-template-columns: 1fr 0.75fr;
    background: var(--green-background);
    box-shadow: 0px 6px 7px rgba(0,0,0,0.25);
}


@media (max-width: 980px) {
    .content{
        gap: 12px;
        grid-template-columns: 1fr;
    }
}


.message{
    gap: 12px;
    display: flex;
    border-radius: 12px;
    background: #143c4133;
    flex-direction: column;
}




.send_button{
    width: 100%;
    font-size: 14px;
    padding: 14px 22px; 
    background: rgb(70, 84, 85);
}

.close{
    top: 0;
    left: 0;
    z-index: 9;
    width: 100vw;
    height: 100vh;
    position: absolute;
}

.video{
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 9;
    width: 100vw;
    height: 100vh;
    display: flex;
    position: fixed;
    align-items: center;
    pointer-events: none;
    background: #000000AA;
    transition: 500ms ease-in-out;
}

.video[data-active="true"]{
    opacity: 1;
    pointer-events: all;
}

.video > video{
    z-index: 9;
    margin: 0 auto;
    max-height: 50vh;
    border-radius: 32px;
    width: min(90vw,600px);
}

.error{
    text-align: start;
}