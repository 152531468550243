
:root{
    --xs:auto;
    --md:auto;
    --lg:auto;
  }
  .custom-input-container {
      display: flex;
      flex-wrap: wrap;
      transition: 0ms;
      position: relative;
      border-radius: 12px;
      width: calc(var(--lg) / 12 * 100% - 8px);
      border: 1px solid rgba(255,255,255, 0.25);
      transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  
    @media (max-width: 760px) {
      .custom-input-container {
        width: calc(var(--md) / 12 * 100% - 8px);
      }
    }
  
    @media (max-width: 460px) {
      .custom-input-container {
        width: calc(var(--xs) / 12 * 100% - 8px);
      }
    }
  
    .custom-input-container:hover{
      border: 1px solid #EEE;
    }
  
    .input{
      flex: 1 1;
      width: 100%;
      border: none;
      padding: 6px;
      color: #EEE;
      outline: none;
      font-size: 14px;
      background: transparent;
      padding: 30px 10px 10px 15px;
    }
  
    .custom-input-legend{
      top:50%;
      left: 13px;
      font-size: 14px;
      position: absolute;
      pointer-events: none;
      transform: translateY(-50%);
      color: rgba(255,255,255, 0.667);
      transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    .custom-input-legend[data-active="false"]{
      top:17.5px;
      left: 13px;
      font-size: 12px;
    }
  
    
    @keyframes show-up-anim {
      0%{
          opacity: 0;
          transform: scale(0.9);
      }
      100%{
          opacity: 1;
          transform: scale(1);
      }
  }