.container{
    overflow: hidden;
    min-height: auto;
    position: relative;
}

.content{
    display: grid;
    margin: 0 auto;
    max-width: 920px;
    position: relative;
    align-items: center;
}
.text_content{
    z-index: 1;
    display: flex;
    text-align: start;
    position: relative;
    border-radius: 19px;
    flex-direction: column;
    width: min(450px,90vw);
}
@media only screen and (max-width: 760px) {
    .text_content{
        left: 0%;
        margin: 0 auto;
    }
}

.img_container{
    top:0;
    right: 10%;
    display: flex;
    position: absolute;
}
@media only screen and (max-width: 760px) {
    .img_container{
        display: none;
    }
}
.img{
    height: 100%;
    object-fit:contain;
    filter: drop-shadow(rgba(0,0,0, 0.5) 20px 17px 30px);
}
.text{
    font-size: 16px;
    line-height: 1.6;
    padding-left: 15px;
    letter-spacing: 0.09px;
    border-left: 1px solid rgba(0, 0, 0, 0.5);
}
